.terms{
    height: inherit;
    width: 100%;
    /* background-color: red; */
    /* background-image: url('../../assets/terms/terms_and_conditions.png'); */
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    /* object-fit: 100% 100%; */
    position: absolute; 
    border: 1px solid green;
    /* padding: 10px; */
    /* z-index: -1;  */
}