/* responsive font size for 13 to 16 px */
/* 1rem=16px */
/* <style> */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap');
/* </style> */

.apply-maxWidth{
   max-width:1536px;
   margin-left:auto;
   margin-right:auto;
   padding:1rem;
}
*{
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}
.responsive_fontsize14{
    font-size: clamp(0.125rem, 2vw + 0.5rem,0.75rem)
    !important                                     
 }
 
 .responsive_fontsize16{
       font-size:clamp(0.25rem,1vw + 0.3rem,1rem)!important                                
 }
 .responsive_fontsize18{
    font-size: clamp(0.125rem, 1vw + 0.5rem ,1.125rem)
    !important                                     
  }
  
 /* clamp(minimum,current,maximum) */
 .responsive_fontsize20{
    font-size: clamp(0.225rem, 0.4vw + 0.5rem ,1.25rem)
    !important                                 
 }
 .responsive_fontsize24{
    font-size: clamp(0.75rem, 1vw + 0.5rem,1.5rem) !important;                                     
 }
 .responsive_fontsize25{
   font-size: clamp(0.8rem,1.25vw + 0.5rem,1.5625rem)  !important
 }
 .responsive_fontsize26{
    font-size: clamp(1rem, 1.5vw + 0.5rem,1.625rem) !important;                                     
 }
 .responsive_fontsize32{
    font-size: clamp(1rem, 1.5vw + 0.4rem,2rem) !important;                                     
 }

 .responsive_fontsize64{
   font-size: clamp(1.25rem, 2vw + 0.5rem,4rem) !important;                                     
}

 /* Center Carousel React Slick */
 .center__carousel .slick-slide > div {
    display: grid;
    place-items: center;
    margin:0 auto;
    padding: 0px;
   
  }
  
 