.filtering{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0rem;
}

.filter_section{
    height: auto;
    width: auto ;
    background-color: white;
    padding: 2rem 3rem 2rem 3rem;
    align-items: center;
    justify-content: center;    
}

.select{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.filtering.filter-open .filter_section {
    display: flex;
}

/* CSS animation for text fade-in */
@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .header-line .fade-in-text {
    opacity: 0; /* Initially set to transparent */
    animation: fadeIn 1s ease forwards; /* Apply animation */
  }
  